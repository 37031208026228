/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import CardsFooter from "components/Footers/CardsFooter";
import { Helmet } from "react-helmet";
import { withNamespaces } from "react-i18next";

class Contacts extends React.Component {
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        const { t } = this.props;
        return (
            <>
                <Helmet>
                    <title>Vivo Max Pro Clean - {t("Contacts")}</title>
                    <link rel="canonical" href={"https://vivomaxpro.com/contacts"} />
                </Helmet>
                <DemoNavbar />
                <main ref="main">
                    <section className="parallax header-section pdp-header section section-lg section-shaped pb-250">
                        <div className="shape shape-style-1 shape-default">
                            {/* <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span /> */}
                        </div>
                        <Container className="pt-lg-7">
                            <Row className="justify-content-center">
                                <Col lg="8">
                                    <Card className="bg-gradient-secondary shadow">
                                        <CardBody className="p-lg-5">
                                            <h4 className="mb-1 h-primary-border">{t("ContactUsTitle")}</h4>
                                            <p>
                                                {t("ContactUsSubtitle")}
                                            </p>
                                            <p className="mb-0">
                                                <i class="fa fa-phone"></i> +359 (0) 884497900<br />
                                                <i class="fa fa-envelope"></i> info@vivomaxpro.com
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </main>
                <SimpleFooter />
            </>
        );
    }
}

export default withNamespaces()(Contacts);
