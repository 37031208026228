/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Card, Container, Row, Col, ButtonGroup } from "reactstrap";
import { Helmet } from "react-helmet";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import axios from "axios";
import CardsFooter from "components/Footers/CardsFooter";
import { Link } from "react-router-dom";
import Badge from "reactstrap/lib/Badge";
import { withNamespaces } from "react-i18next";
import { API_URL, PRODUCTS_URL } from "../constants";

class ProductDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: null,
            selectedPackage: "5L"
        }
    }
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;

        let productId = null;
        if (this.props.match.params.productId) {
            productId = this.props.match.params.productId
        }

        axios.get(PRODUCTS_URL).then((response) => {
            this.setState({
                product: response.data.find((prd) => prd.id === productId)
            })
        })
    }
    render() {
        const { product } = this.state;
        const { t } = this.props;
        let pTr = null;
        if (product) pTr = t("Products", { returnObjects: true })[product.id];

        return (
            <>
                {product && <Helmet>
                    <title>Vivo Max Pro Clean - {pTr.title}</title>
                    <link rel="canonical" href={API_URL + "/product/" + product.id} />
                </Helmet>}
                <DemoNavbar />
                {product && <main className="profile-page product-details" ref="main">
                    <section className="parallax header-section pdp-header section-profile-cover section-shaped my-0">
                        {/* Circles background */}
                        <div className="shape shape-style-1 shape-default alpha-4">
                            {/* <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span /> */}
                        </div>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </section>
                    <section className="section">
                        <Container>
                            <Row className="card-profile mt--300">
                                <Col>
                                    <Button className="mb-4 back-btn" color="primary" tag={Link} to="/"><i class="fa fa-chevron-left"></i> {t("NavigateBack")}</Button>

                                    <Card className="shadow">
                                        <div className="px-4">
                                            <Row className="mt-2">
                                                <Col>

                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col xl="6" lg="8" md="8" sm="12" className="text-center">
                                                    {product.packaging.map((packSize) => {
                                                        const style = {maxHeight: "600px", maxWidth: "100%"};
                                                        if(packSize !== this.state.selectedPackage) style.display = "none";
                                                        return <img
                                                            style={style}
                                                            alt="..."
                                                            height="auto"
                                                            width="auto"
                                                            className="pdp-image"
                                                            src={product.imageSrc[packSize]}
                                                        />
                                                    })}
                                                </Col>
                                                <Col>
                                                    <div className="text-left">
                                                        <h1>
                                                            <Badge pill color="primary">{product.brand}</Badge><br />
                                                            <span className="font-weight-light">{pTr.title}</span>
                                                        </h1>
                                                        {/* <ul className={"unstyled-list pd-attributes-list"}>
                                                            <li><Badge pill>{t("Packaging")}</Badge><Badge pill color="primary">5L</Badge></li>
                                                        </ul> */}
                                                        <div className="mt-2 py-3">
                                                            <Row>
                                                                <Col>
                                                                    <h5>{t("Packaging")}</h5>
                                                                    <ButtonGroup>
                                                                        {product.packaging.map((packSize) => {
                                                                            return <Button onClick={() => this.setState({ selectedPackage: packSize })} outline={this.state.selectedPackage !== packSize} color="primary">{packSize}</Button>
                                                                        })}
                                                                    </ButtonGroup>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="mt-3 py-3">
                                                            <Row>
                                                                <Col>
                                                                    <h5>{t("Purpose")}</h5>
                                                                    <p>
                                                                        {pTr.purpose}
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="mt-3 mb-3">
                                                            <Row>
                                                                <Col>
                                                                    <h5>{t("Use")}</h5>
                                                                    {pTr.use.split("\n").map((line) => <p className="mb-0">{line}</p>)}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </main>}
                <SimpleFooter />
            </>
        );
    }
}

export default withNamespaces()(ProductDetails);
